.footer {
    color: #fff;
    padding: 110px 0;
    font-family: "Geist", sans-serif;
    @include _840 {
        padding: 60px 0;
    }

    // &__inner {
    //     display: flex;
    //     justify-content: space-between;
    // }

    .logo {
        display: flex;
        align-items: center;
        gap: 30px;

        .caption {
            color: var(--color-yellow);
            font-size: 250%;
            font-weight: 700;
            text-transform: uppercase;
        }

        .desc {
            color: #949aa2;
            text-transform: uppercase;
        }
    }
}

.footer-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 70px;
    flex-wrap: wrap;
    @include _840 {
        gap: 30px;
        margin-bottom: 50px;
    }

    &--center {
        align-items: center;
    }

    &:nth-child(2) {
        margin-bottom: 60px;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.footer-col {
    @include _840 {
        width: 100%;
    }

    &:nth-child(2) {
        text-align: right;
    }
}

.get-touch {
    font-size: 420%;
    font-weight: 300;
    line-height: 1;
    text-transform: uppercase;
    text-decoration: none;

    display: flex;
    align-items: center;
    gap: 45px;
    transition: color .2s;
    @include _840 {
        font-size: 200%;
        gap: 25px;
        justify-content: center;
    }

    &:hover {
        color: var(--color-yellow);

        .light {
            color: #fff;
        }

        .icon {
            transform: rotate(225deg);
        }
    }

    .light {
        font-weight: 700;
        transition: color .2s;
    }

    .icon {
        height: 45px;
        width: 45px;
        transform: none;
        transition: transform .2s;
        @include _840 {
            height: 25px;
            width: 25px;
        }
    }
}

.copyright {
    color: #bec2c7;
    font-size: 70%;
    @include _840 {
        text-align: center;
    }

    span {
        display: inline-block;
        margin: 0 5px;
    }
}

.footer-menu {
    font-size: 115%;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 25px;
    @include _840 {
        align-items: center;
    }

    // a {

    //     &:last-child {
    //         font-weight: 300;
    //     }
    // }
}

.footer-question {
    padding-top: 27px;
    position: relative;
    @include _840 {
        text-align: center;
    }

    &::before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 57px;
        border-top: 3px solid var(--color-yellow);
        @include _840 {
            right: 50%;
            transform: translateX(50%);
        }
    }

    p {
        font-size: 90%;
        font-weight: 300;
        margin-bottom: 8px;
    }

    a {
        font-size: 115%;
    }
}