.section-about {
    background-color: #8687d9;
    position: relative;

    &__title {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        text-align: right;
        width: 100%;
        @include _840 {
            text-align: left;
        }

        .container {
            padding-right: 80px;
            @include _840 {
                padding-right: 20px;
            }
        }

        .show {

            h1 {
                opacity: 1;
            }

            p {
                opacity: 1;
            }
        }

        h1 {
            line-height: 1;
            margin-bottom: 15px;

            opacity: 0;
            transition: opacity 1s .5s;
        }

        p {
            font-size: 110%;
            font-weight: 300;
            line-height: 1.5;

            opacity: 0;
            transition: opacity 1s 1s;

            @include _840 {
                font-size: 120%;

                br {
                    display: none;
                }
            }
        }
    }
}

.about-content {
    background-color: #2b384a;

    .container {
        padding-right: 80px;
        @include _840 {
            padding-right: 20px;
        }
    }

    &__row {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 5;
        @include _840 {
            flex: 1 1 100%;
            flex-wrap: wrap;
            width: 100%;
        }

        &:first-child {
            position: relative;
            padding: 41px 0;
            @include _840 {
                padding: 0;
                margin-bottom: 30px;
            }

            .about-card {
                position: absolute;
                top: -290px;
                width: 50%;
                @include _840 {
                    top: 0;
                    position: relative;
                }
            }
        }
    }

    &__main {
        margin-bottom: 75px;
        padding-left: 30px;
        @include _840 {
            padding-left: 0;
            margin-bottom: 30px;
        }

        h3 {
            font-weight: 100;
            font-size: 210%;
            margin-bottom: 15px;
            @include _840 {
                font-size: 150%;
            }
        }

        p {
            line-height: 1.5;
            font-weight: 300;
            padding-right: 100px;
            @include _840 {
                padding-right: 0;
            }
        }
    }
}

.about-img {
    background-color: #2b384a;
    margin-top: -55px;
    @include _840 {
        margin-top: 30px;
        padding-bottom: 55px;
    }

    img {
        display: inline-block;
        opacity: 0;
        transform: translateY(25px);
        transition: .8s;

        &.show {
            opacity: 1;
            transform: none;
        }
    }

    &__action {
        margin-top: 75px;
        padding-bottom: 75px;
        text-align: center;
        @include _840 {
            padding-bottom: 20px;
        }

        .btn-scroll {
            margin: 0 auto;
        }
    }
}

.about-card {
    background-color: var(--color-yellow);
    flex: 0 0 50%;
    padding: 74px 96px;
    height: 372px;
    @include _840 {
        flex: 1 1 100%;
        height: auto;
        padding: 30px 20px;
        margin: 0 -20px;
    }

    h3 {
        color: #131313;
        font-weight: 100;
        font-size: 170%;
        margin-bottom: 30px;
        @include _840 {
            font-size: 150%;
            margin-bottom: 15px;
        }
    }

    p {
        color: #7f6c2a;
        line-height: 1.5;
        font-size: 90%;
        font-weight: 300;
    }
}