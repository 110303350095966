.section-contact {
    background-color: #786c69;
    position: relative;

    img {
        @include _1280 {
            object-position: 13%;
        }
    }

    &__title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 100%;
        @include _840 {
            text-align: left;
            padding: 0 20px;
            top: 60%;
        }

        &.show {

            h1 {
                opacity: 1;
            }

            p {
                opacity: 1;
            }
        }

        h1 {
            color: #fff;
            font-weight: 100;
            line-height: 1;
            margin-bottom: 15px;

            opacity: 0;
            transition: opacity 1s .5s;

            .light {
                font-weight: 700;
            }
        }

        p {
            font-size: 110%;
            font-weight: 300;
            line-height: 1.5;

            opacity: 0;
            transition: opacity 1s 1s;

            @include _840 {
                font-size: 90%;
            }
        }
    }
}

.contact-content {
    background-color: #2b384a;
    padding-bottom: 140px;
    @include _840 {
        padding-bottom: 0;
    }
}

.contact-card {
    background-color: var(--color-yellow);
    color: #293546;
    font-family: "Geist", sans-serif;
    height: 480px;
    max-width: 830px;
    margin: -110px auto 0;
    position: relative;
    padding: 55px 83px 70px;
    @include _840 {
        margin: 0 auto;
        padding: 30px 20px;
        height: auto;
    }

    &__title {
        margin-bottom: 40px;

        h2 {
            font-size: 280%;
            font-weight: 600;
            margin-bottom: 10px;
            @include _840 {
                font-size: 150%;
            }
        }

        p {
            color: #9f8c4a;
            font-size: 90%;
            font-weight: 300;
        }
    }

    &__content {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }
}

.form {

    .form-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 40px;
        margin-bottom: 35px;
        flex-wrap: wrap;

        &:last-child {
            margin-top: 45px;
            margin-bottom: 0;
        }
    }

    .form-col {
        flex: 1;

        label {
            display: block;
            font-size: 90%;
            font-weight: 600;
            margin-bottom: 10px;
        }

        input {
            color: inherit;
            border: none;
            border-bottom: 1px solid #fff;
            background-color: transparent;
            outline: none;
            padding: 5px 0;
            font-size: 80%;
            font-weight: 300;
            width: 100%;
            min-width: 190px;

            &::placeholder {
                color: #2935468f;
            }

            &:focus {
                border-color: #293546;
            }
        }
    }

    .copyright {
        color: #a38d3d;
    }
}