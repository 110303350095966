// Main Company promo
.company-promo {
    display: flex;
    padding: 52px 0;
    @include _840 {
        padding: 30px 0;
    }

    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px;
    }

    &__content {
        color: #fff;
        display: flex;
        align-items: center;
        gap: 40px;
        padding-bottom: 30px;
        position: relative;
        flex-wrap: wrap;
        @include _840 {
            gap: 20px;
        }

        &::before {
            content: '';
            border-top: 2px solid #fff;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
        }

        p {
            font-size: 200%;
            font-weight: 300;
            text-transform: uppercase;
            @include _840 {
                font-size: 120%;
            }
        }
    }

    &__action {
        display: flex;
        align-items: center;
        gap: 60px;
        @include _1280 {
            margin: 0 auto;
        }

        .desc {
            color: #7f8690;
            font-size: 70%;
        }
    }
}

// Main about
.main-about {
    padding: 100px 0 90px;
    @include _1280 {
        padding: 70px 0;
    }
    @include _840 {
        padding: 50px 0;
    }

    &__inner {
        display: flex;
        flex-wrap: wrap;
        @include _840 {
            gap: 30px;
        }

        & > * {
            flex: 1 1 50%;
        }
    }
}

.main-about-action {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
    @include _840 {
        justify-content: space-between;
        gap: 20px;
    }

    span {
        color: #7f8690;
        font-size: 12px;
        white-space: nowrap;
        text-align: right;
    }

    a {
        border-radius: 50%;
        border: 1px solid #949aa2;
        color: var(--color-yellow);
        font-size: 150%;
        font-weight: 100;
        height: 190px;
        width: 190px;
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        @include _840 {
            height: 45vw;
            width: 45vw;
        }

        &:hover {
            color: var(--color-blue);
            border-color: var(--color-yellow);

            &::after {
                transform: none;
            }
        }

        &::after {
            content: '';
            background-color: var(--color-yellow);
            position: absolute;
            height: 100%;
            width: 100%;
            transform: translateY(100%);
            transition: transform .2s;
            z-index: -1;
        }
    }
}

.main-about-desc {
    padding-left: 35px;
    border-left: 3px solid var(--color-yellow);

    opacity: 0;
    transform: translateX(20px);
    transition: 1s;

    @include _840 {
        padding-left: 20px;
    }

    &.show {
        opacity: 1;
        transform: none;
    }

    p {

        &:first-child {
            font-size: 210%;
            font-weight: 100;
            margin-bottom: 25px;
            @include _1280 {
                font-size: 160%;
            }
            @include _840 {
                font-size: 130%;
            }
        }

        &:last-child {
            color: #949aa2;
            line-height: 1.5;
            font-weight: 300;
            @include _840 {
                font-size: 90%;
            }
        }
    }
}

// Main mission
.main-mission {
    overflow: hidden;
    margin-bottom: 110px;
    @include _840 {
        margin-bottom: 80px;
    }

    &__inner {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        @include _840 {
            gap: 30px;
        }

        & > * {
            flex: 1 1 50%;
        }
    }

    &__text {
        padding-left: 40px;
        padding-right: 20px;
        border-left: 3px solid var(--color-yellow);
        font-size: 150%;
        font-weight: 100;

        opacity: 0;
        transform: translateX(-20px);
        transition: 1s;

        @include _1280 {

            br {
                display: none;
            }
        }
        @include _840 {
            font-size: 110%;
            padding-left: 20px;
        }

        &.show {
            opacity: 1;
            transform: none;
        }
    }

    &__img {
        margin-right: calc((100vw - 1298px) / -2);
        flex-basis: calc(50% + ((100vw - 1298px) / 2));
        @include _1280 {
            margin-right: 0;
            flex-basis: 50%;
        }
        @include _840 {
            flex-basis: 100%;
        }
    }
}

// Main Presentations
.presentations {
    background-attachment: fixed;
    background-image: url(../img/bg-presentations.jpg);
    background-size: cover;
    background-position: center;
    height: 600px;
    padding: 100px 0 140px;
    max-width: 1920px;
    margin: 0 auto;
    text-align: center;
    @include _1280 {
        height: auto;
    }
    @include _840 {
        padding: 80px 0;
    }

    h2 {
        font-size: 300%;
        font-weight: 300;
        margin-bottom: 45px;
        text-transform: uppercase;
        @include _840 {
            font-size: 180%;
        }
    }

    &__inner {
        display: flex;
        justify-content: center;
        gap: 12px;
        flex-wrap: wrap;
    }
}

.presentation-link {
    height: 240px;
    width: 490px;
    font-size: 170%;
    font-weight: 300;
    padding: 40px 47px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    text-align: left;
    transform: none;
    transition: transform .2s;
    @include _840 {
        height: 30vh;
        width: 80%;
        font-size: 140%;
        padding: 30px;
    }

    &:hover {
        transform: translateY(-10px);

        .icon {
            transform: rotate(225deg);
        }
    }

    span {
        line-height: 1;
    }

    .icon {
        height: 26px;
        width: 26px;
        transition: transform .2s;
    }

    &:first-child {
        background-color: #9E9E9E;
        color: #fff;
    }

    &:last-child {
        background-color: var(--color-yellow);
        color: #293546;
    }
}

// Main Industry
.main-industry {
    margin-bottom: 80px;

    &__title {
        display: flex;
        align-items: center;
        gap: 45px;
        border-bottom: 2px solid #545d6b;
        padding-bottom: 50px;
        margin-bottom: 60px;
        flex-wrap: wrap;
        @include _840 {
            gap: 15px;
            padding-bottom: 20px;
            margin-bottom: 40px;
        }

        h2 {
            font-weight: 300;
            font-size: 400%;
            line-height: 1;
            text-transform: uppercase;
            @include _840 {
                font-size: 180%;
            }
        }

        .light {
            font-weight: 700;
        }

        .more {
            color: #7f8690;
            font-weight: 100;
        }
    }

    &__inner {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    &__row {
        display: flex;
        gap: 4px;
        @include _840 {
            flex-wrap: wrap;
        }
    }

    &__col {
        flex: 1;
        position: relative;
        overflow: hidden;

        opacity: 0;
        transform: translateY(15px);
        transition: 1s;

        @include _840 {
            flex: 1 1 100%;
            min-height: 172px;
        }

        &.show {
            opacity: 1;
            transform: none;
        }

        &:hover {

            img {
                transform: scale(1.05);
            }
        }

        img {
            height: 100%;
            width: 100%;
            object-position: center center;
            object-fit: cover;
            transform: none;
            transition: transform .4s;
            will-change: transform;
        }

        .caption {
            position: absolute;
            left: 90px;
            bottom: 70px;
            font-weight: 300;
            font-size: 180%;
            padding-top: 25px;
            @include _840 {
                font-size: 120%;
                bottom: 30px;
                left: 30px;
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 50px;
                border-top: 3px solid var(--color-yellow);
            }
        }
    }
}