.menu {
    // display: none;
    color: #2d2b29;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--color-yellow);
    z-index: 99;
    opacity: 0;
    pointer-events: none;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: .2s;

    &.open {
        pointer-events: all;
        opacity: 1;
    }

    &__mail {
        text-align: center;

        p {
            font-size: 90%;
            font-weight: 700;
            margin-bottom: 7px;
        }
    }

    menu {
        margin: 15vh 0 10vh;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        text-align: center;
        font-size: 220%;
        font-weight: 300;
        text-transform: uppercase;
        @include _840 {
            font-size: 120%;
        }
    }

    .close {
        @include no-btn();
        color: inherit;
        font-size: 200%;
        position: absolute;
        top: 10%;
        right: 10%;
        @include _840 {
            top: 5%;
            right: 5%;
        }
    }
}