.section-industry {
    background-color: #152228;
    position: relative;

    &__title {
        position: absolute;
        top: 60%;
        width: 100%;
        transform: translateY(-50%);
    }

    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 145px;
        flex-wrap: wrap;
        @include _840 {
            margin-bottom: 80px;
        }

        &.show {

            h1 {
                opacity: 1;
            }

            p {
                opacity: 1;
            }
        }

        h1 {
            opacity: 0;
            transition: opacity 1s .5s;
        }

        p {
            font-size: 150%;
            font-weight: 300;

            opacity: 0;
            transition: opacity 1s 1s;

            @include _840 {
                font-size: 120%;
                margin-top: 8px;
            }
        }
    }

    &__text {
        color: #7f8690;
        font-size: 80%;
        font-weight: 400;
        text-transform: uppercase;

        span {
            display: inline-block;
            margin: 0 5px;
        }
    }
}

.industry-content {
    background-color: #2b384a;
    padding: 80px 0 75px;
    @include _840 {
        padding: 50px 0;
    }
}

.industry-section {
    padding-bottom: 60px;
    border-bottom: 2px solid #55606e;
    padding-top: 80px;
    @include _840 {
        padding-bottom: 0;
        padding-top: 50px;
    }

    &:first-child {
        padding-top: 0;
    }

    &:last-of-type {
        border: none;
    }
}

.industry-row {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    flex-wrap: wrap;

    h3 {
        font-size: 210%;
        font-weight: 100;
        line-height: 1;
        margin-bottom: 35px;
        @include _840 {
            font-size: 180%;
            margin-bottom: 25px;
        }
    }

    p {
        color: #7f8690;
        line-height: 1.5;
        font-weight: 300;
        font-size: 90%;
        margin-bottom: 30px;
        @include _840 {
            margin-bottom: 15px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.industry-col {
    flex: 0 0 50%;
    @include _840 {
        flex: 1 1 100%;
    }

    &:first-child {
        padding-right: 80px;
        @include _840 {
            padding-right: 0;
        }
    }

    &.--img {
        flex-basis: calc(50% + ((100vw - 1298px)/ 2));
        opacity: 0;
        transition: opacity .8s;
        @include _1280 {
            flex-basis: 50%;
        }
        @include _840 {
            flex-basis: 100%;
            order: 2;
            margin-top: 15px;
        }

        &.show {
            opacity: 1;
        }
    }

    &.--img-right {
        margin-right: calc((100vw - 1298px)/ -2);
        @include _1280 {
            margin-right: 0;;
        }
    }

    &.--img-left {
        margin-left: calc((100vw - 1298px)/ -2);
        @include _1280 {
            margin-left: 0;;
        }
    }
}