.hero {
    height: 620px;
    max-width: 1920px;
    margin: 0 auto;

    background-color: #C4C4C4;
    @include _840 {
        margin-top: -136px;
        height: 70vh;
    }

    video {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}