.header {
    background-color: transparent;
    border-top: 13px solid var(--color-yellow);
    height: 143px;
    position: relative;
    z-index: 10;
    @include _840 {
        border-top-width: 8px;
    }

    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .logo {
        background-color: var(--color-yellow);
        height: 102px;
        width: 94px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 6px;
        text-align: center;

        .caption {
            color: #1A1A1A;
            line-height: 1.3;
            font-size: 82.5%;
            font-weight: 700;
            text-transform: uppercase;
        }
    }
}

.header-actions {
    display: flex;
    align-items: center;
    gap: 140px;
}

.btn-menu {
    @include no-btn();
    color: #fff;
    font-family: "Onest", sans-serif;
    font-weight: 300;
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 10px;
    margin-right: -10px;

    &:hover {

        .strokes::after {
            width: 100%;
        }
    }

    .strokes {
        position: relative;
        width: 42px;

        &::before, &::after {
            content: '';
            border-top: 1.5px solid;
            position: absolute;
            right: 0;
            top: 50%;
            transition: .2s;
        }

        &::before {
            width: 100%;
            transform: translateY(calc(-50% - 2.5px));
        }

        &::after {
            width: 32px;
            transform: translateY(calc(-50% + 2.5px));
        }
    }
}