:root {
    --color-blue: #293546;
    --color-yellow: #FDD854;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-size: 16px;
    -webkit-text-size-adjust: none;
}

html,
body {
    min-height: 100%;
    position: relative;
    font-size: 16px;
}

body {
    background-color: var(--color-blue);
    color: #fff;
    font-size: 100%;
    font-family: "Onest", sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    line-height: normal;
}

.no-scroll {
    overflow: hidden;
}

ul,
ol,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

h1 {
    color: var(--color-yellow);
    font-size: 450%;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
    @include _840 {
        font-size: 250%;
    }
}

svg {
    display: inline-block;
    fill: currentColor;
    height: 100%;
    width: 100%;
}

a {
    color: inherit;
    position: relative;
    text-decoration: none;

    &:not(.no-link):after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        border-top: 1px solid;
        transform: scaleX(0);
        transition: transform .2s;
        transform-origin: 100% 100%;
    }

    &:hover {

        &::after {
            transform-origin: 0 0;
            transform: scaleX(1);
        }
    }
}

img {
    display: block;
    max-width: 100%;
    margin: 0;
}

.btn {
    @include no-btn();
    color: #000;
    padding: 14px 30px;
    line-height: 1;
    font-size: 80%;
    font-family: "Geist", sans-serif;
    display: flex;
    align-items: center;
    gap: 32px;

    .icon {
        height: 16px;
        width: 16px;
    }
}

.btn-call {
    background-color: #fff;
    padding: 14px 28px 14px 36px;
    transition: .2s;
    @include _840 {
        display: none;
    }

    &:hover {
        background-color: var(--color-yellow);
    }
}

.btn-scroll {
    @include no-btn();
    background-color: transparent;
    border: 1px solid #7f8690;
    color: #d4d7da;
    line-height: 1;
    font-family: "Geist", sans-serif;
    display: flex;
    align-items: center;
    gap: 26px;
    padding: 15px 32px 15px 52px;
    white-space: nowrap;
    transition: .2s;
    @include _840 {
        padding: 12px 20px 12px 30px;
        gap: 16px;
        font-size: 90%;
    }

    .icon {
        height: 26px;
        width: 26px;
    }

    &:hover {
        color: #2d2b29;
        background-color: var(--color-yellow);
        border-color: #2d2b29;
    }
}

.btn-submit {
    @include no-btn();
    color: inherit;
    background-color: #fff;
    padding: 12px 45px;
    font-size: 80%;
    font-weight: inherit;
}

.icon {
    svg {
        display: block;
        height: 100%;
        width: 100%;
        fill: currentColor;
    }
}

.light {
    color: var(--color-yellow);
}

.container {
    margin: 0 auto;
    padding: 0 40px;

    max-width: 1360px;
    width: 100%;
    @include _840 {
        padding: 0 20px;
    }
}

.top-section {
    max-width: 1920px;
    margin: -130px auto 0;
    height: 637px;
    width: 100%;
    @include _840 {
        margin: -135px auto 0;
        height: 75vh;
    }

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.content {
    line-height: 1.5;

    h1 {
        font-size: 28px;
        font-weight: 400;
        margin-bottom: 30px;
    }

    ol {
        padding-left: 16px;

        ol {
            padding-left: 0;
        }

        li {
            position: relative;
            margin: 15px 0;
        }
    }

    & > ol > li {
        counter-increment: inst;

        li::before {
            content: counter(inst) ".";
            position: absolute;
            left: -32px;
        }

        & > ol {
            padding-left: 16px;
        }
    }

    & > ol > li > ol > li {
        counter-increment: str;

        li::before {
            left: -48px;
        }

        li::after {
            content: counter(str) ".";
            position: absolute;
            top: 0;
            left: -32px;
        }

        & > ol {
            padding-left: 16px;
        }
    }

    p {
        margin-bottom: 20px;
    }
}